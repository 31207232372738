<template>
  <div class="question questionare-inner">
    <div class="create-password" data-app>
      <Content>
        <h1 class="h4 text-center create-new-password-title">Create new password</h1>
        <CreatePassword @submit="changePassword" />
      </Content>
    </div>
  </div>
</template>

<script>
import CreatePassword from '@components/CreatePassword/CreatePassword.vue';
import { mapGetters } from 'vuex';
import differentsServices from '@helpers/differentsServices';

export default {
  components: {
    CreatePassword,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getSecurityCode: 'auth/getSecurityCode',
      getRedirectAuth: 'auth/getRedirectAuth',
    }),
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
  },
  methods: {
    redirectToMainPage() {
      this.$router.push({ name: 'main' });
    },
    backToAnonymous() {
      this.$route.push({
        name: 'questionnare',
      });
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
    savePhoto(res) {
      if (res.trim().startsWith('document')) {
        const srcStart = res.indexOf('src') + 5;
        const titleStart = res.indexOf('title') + 7;
        const result = {
          link: res.substring(srcStart, res.indexOf("'", srcStart)),
          title: res.substring(titleStart, res.indexOf("'", titleStart)),
        };
        this.$api.auth.saveUserPhoto(result, this.getProfile.id);
      } else {
        this.$api.auth.saveUserPhoto({ link: '', title: '' }, this.getProfile.id);
      }
    },
    async changePassword(formData) {
      this.loading = true;
      const dataForRequest = {
        password: formData.password,
        code: this.getSecurityCode,
        questionId: formData.selectSecretQuestion.questionId,
        secretAnswer: formData.secretAnswer,
      };

      // eslint-disable-next-line no-underscore-dangle
      try {
        await this.$store.dispatch('auth/newPassword', {
          formData: dataForRequest,
          userId: this.getProfile.id,
        });
        differentsServices.avatarPhotoService(this.getProfile.email, this.getProfile.id);
        this.loading = false;
        this.$router.push({ name: 'building-credibility-score' });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .highlighting-name {
    text-transform: uppercase;
    color: red;
    font-weight: bold;
  }
  .question {
    height: 100%;
    background: #F3F4F6;
  }
  .header-initial {
    max-width: 480px !important;
    align-self: center;
    padding-top: 20px;
    height: 100%;
  }
  .backgroundColor1 {
    background-color: #F2E7FA;
  }
  .backgroundColor2 {
    background-color: #FFCBCB;
  }
  .questionare-inner {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  .header-detail {
    text-align: center;
  }
  .header-detail_special_text {
    font-weight: bold;
  }
  .create-new-password-title {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
    margin-bottom: 15px;
  }
  .header_text__1 {
    color: #FF5151;
  }
  .header__logo{
    width: 7vh;
    height: 7vh;
    @media (max-height: $xxsMaxHeight) {
      width: 5vh;
      height: 5vh;
    }
  }
  .header-margin {
    margin-top: 10px;
  }
  .logo-block {
    display: flex;

  }
  .create-password {
    margin: 105px 20px 15px;
  }
  .logo-innerworks {
    cursor: pointer;
    padding-left: 25px;
    padding-top: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    background: #F3F4F6;
  }
  .logo-text {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $txtColorNewDesignMain;
    padding-left: 13px;
    padding-top: 3px;
  }
  @media (max-width: 356px) {
    .logo-text {
      font-size: 15px;
    }
  }
  @media (max-width: 325px) {
    .logo-text {
      font-size: 13px;
    }
  }
  @media (max-width: 301px) {
    .logo-text {
      font-size: 11px;
    }
  }
</style>
